module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"sam-site","accessToken":"MC5YdWdZZlJBQUFDSUFkY0lj.Ge-_vSI0QVUkNu-_vWdsHE9qHATvv70Y77-977-977-977-9Ee-_vXklG--_ve-_vU_vv73vv70","schemas":{"case_study":{"Content":{"uid":{"type":"UID","config":{"label":"SEO","placeholder":"SEO"}},"header_image":{"type":"Image","config":{"constraint":{"width":1920,"height":1080},"thumbnails":[],"label":"Header Image"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title","placeholder":"Case study title"}},"subtitle":{"type":"StructuredText","config":{"single":"heading2","label":"Subtitle","placeholder":"Subtitle"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, list-item, o-list-item","label":"content","placeholder":"Case study content"}}}}},"lang":"*","typePathsFilenamePrefix":"prismic-typepaths---gatsby-source-prismic-test-site"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bella - Gatsby Starter Portfolio","short_name":"Bella","description":"A bright single-page portfolio starter with big typography & images for Gatsby.","start_url":"/","background_color":"#350d27","theme_color":"#b710a1","display":"fullscreen","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
